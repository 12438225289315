
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {AppCoachMatchesRootListIndex} from "@/models/app/coach/matches/root/list/index";
import {AppCommonUser} from "@/models/app/common/User";
import {AppCommonMatchCategory} from "@/models/app/common/match-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppCoachMatchesRootListFormCreateIndexVue from "@/views/app/coach/matches/root/list/form/create.vue";
import AppCoachMatchesRootListFormUpdateIndexVue from "@/views/app/coach/matches/root/list/form/update.vue";
import AppCoachMatchesRootListFormRemoveIndexVue from "@/views/app/coach/matches/root/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  props: [
    "players",
    "matchCategories",
  ],
  components: {
    ElRow,
    ElCol,
    AppCoachMatchesRootListFormCreateIndexVue,
    AppCoachMatchesRootListFormUpdateIndexVue,
    AppCoachMatchesRootListFormRemoveIndexVue,
  },
})
export default class AppCoachMatchesRootListIndexVue extends Vue {
  isLoading: boolean | null = false;
  formData: any = {
    filter: {
      user_id: "*",
      match_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };
  moment: any = null;

  players: Array<AppCommonUser> = [];
  matchCategories: Array<AppCommonMatchCategory> = [];

  matches: Array<AppCoachMatchesRootListIndex> = [];

  async getMatches(formData: any = null): Promise<void> {
    this.isLoading = true;

    if (formData) {
      this.formData = formData;
    }

    await getRequest({
      uri: `/common/matches`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matches = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getMatches();
  }
}
