export interface AppCoachMatchesRootListFormRemove {
  id: string;
  user_id: string;
  organization_id: string;
  match_category_id: string;
  match_category: {
    id: string;
    content: {
      name: string;
      fields: any;
    };
  };
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    date: string;
    fields: any;
  };
}

export class AppCoachMatchesRootListFormRemove implements AppCoachMatchesRootListFormRemove {
  id: string;
  user_id: string;
  organization_id: string;
  match_category_id: string;
  match_category: {
    id: string;
    content: {
      name: string;
      fields: any;
    };
  };
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    date: string;
    fields: any;
  };

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.match_category_id = "";
    this.match_category = {
      id: "",
      content: {
        name: "",
        fields: [],
      },
    };
    this.user = {
      id: "",
      content: {
        name: "",
        surname: "",
      },
    };
    this.content = {
      date: "",
      fields: [],
    };
  }
}